import {
  Box,
  Button,
  CardActionArea,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseBtn from "../../../../context/CloseBtn";
import {
  Article,
  ArticleOutlined,
  AudioFileOutlined,
  Image,
  ImageOutlined,
  Send,
  SlowMotionVideo,
} from "@mui/icons-material";
import TextFieldNew from "../../../../components/TextFieldNew";
import { GlobalContext } from "../../../../context/GlobalContext";
import axios from "axios";

const SendBottomMedia = ({ data, INBOX, state, setState }) => {
  const { hitAxios } = React.useContext(GlobalContext);

  async function sendAudio() {
    if (!state.url && state.loading) return;
    setState({ ...state, loading: true });
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/inbox/send_audio`,
          {
            url: state.url,
            toNumber: INBOX?.data?.openedChat?.sender_mobile,
            toName: INBOX?.data?.openedChat?.sender_name,
            chatId: INBOX?.data?.openedChat?.chat_id,
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
            },
          }
        )
        .then((res) => {
          if (!res.data?.success) {
            alert(res.data?.msg);
            setState({ ...state, loading: false, mediaDialog: false, url: "" });
            return;
          }
          setState({
            ...state,
            loading: false,
            text: "",
            mediaDialog: false,
            url: "",
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setState({ ...state, loading: false, mediaDialog: false, url: "" });
        });
    } catch (err) {
      alert("Please check internet conenction");
      console.log(err);
    }
  }

  async function sendDocument() {
    if (!state.url && state.loading) return;
    setState({ ...state, loading: true });
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/inbox/send_doc`,
          {
            url: state.url,
            toNumber: INBOX?.data?.openedChat?.sender_mobile,
            toName: INBOX?.data?.openedChat?.sender_name,
            chatId: INBOX?.data?.openedChat?.chat_id,
            caption: state?.caption || "",
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
            },
          }
        )
        .then((res) => {
          if (!res.data?.success) {
            alert(res.data?.msg);
            setState({ ...state, loading: false, mediaDialog: false, url: "" });
            return;
          }
          setState({
            ...state,
            loading: false,
            text: "",
            mediaDialog: false,
            url: "",
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setState({ ...state, loading: false, mediaDialog: false, url: "" });
        });
    } catch (err) {
      alert("Please check internet conenction");
      console.log(err);
    }
  }

  async function sendVideo() {
    if (!state.url && state.loading) return;
    setState({ ...state, loading: true });
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/inbox/send_video`,
          {
            url: state.url,
            toNumber: INBOX?.data?.openedChat?.sender_mobile,
            toName: INBOX?.data?.openedChat?.sender_name,
            chatId: INBOX?.data?.openedChat?.chat_id,
            caption: state?.caption || "",
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
            },
          }
        )
        .then((res) => {
          if (!res.data?.success) {
            alert(res.data?.msg);
            setState({ ...state, loading: false, mediaDialog: false, url: "" });
            return;
          }
          setState({
            ...state,
            loading: false,
            text: "",
            mediaDialog: false,
            url: "",
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setState({ ...state, loading: false, mediaDialog: false, url: "" });
        });
    } catch (err) {
      alert("Please check internet conenction");
      console.log(err);
    }
  }

  async function sendImage() {
    if (!state.url && state.loading) return;
    setState({ ...state, loading: true });
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/inbox/send_image`,
          {
            url: state.url,
            toNumber: INBOX?.data?.openedChat?.sender_mobile,
            toName: INBOX?.data?.openedChat?.sender_name,
            chatId: INBOX?.data?.openedChat?.chat_id,
            caption: state?.caption || "",
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
            },
          }
        )
        .then((res) => {
          if (!res.data?.success) {
            alert(res.data?.msg);
            setState({ ...state, loading: false, mediaDialog: false });
            return;
          }
          setState({
            ...state,
            loading: false,
            text: "",
            mediaDialog: false,
            url: "",
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setState({ ...state, loading: false, mediaDialog: false, url: "" });
        });
    } catch (err) {
      alert("Please check internet conenction");
      console.log(err);
    }
  }

  async function returnUrl(media) {
    const fd = new FormData();
    fd.append("file", media);
    const res = await hitAxios({
      path: "/api/user/return_media_url",
      post: true,
      admin: false,
      obj: fd,
    });
    if (res.data.success) {
      setState({ ...state, url: res.data.url });
    }
  }

  return (
    <Dialog
      fullWidth
      PaperProps={{
        style: { borderRadius: 10 },
      }}
      key="6"
      onClose={() => setState({ ...state, mediaDialog: false })}
      open={state.mediaDialog}
    >
      <Box p={2}>
        <Stack mb={2} direction={"row"} justifyContent={"flex-end"}>
          <CloseBtn
            onClick={() => setState({ ...state, mediaDialog: false })}
          />
        </Stack>

        <Divider />

        <Box mt={2}>
          <Stack direction={"column"} spacing={2}>
            <Stack alignItems={"center"} direction={"row"} spacing={1}>
              <Chip
                onClick={() => setState({ ...state, type: "IMAGE", url: "" })}
                variant={state.type === "IMAGE" ? "filled" : "outlined"}
                label={data.image}
                icon={<ImageOutlined sx={{ height: 20, width: 20 }} />}
              />
              <Chip
                onClick={() => setState({ ...state, type: "VIDEO", url: "" })}
                variant={state.type === "VIDEO" ? "filled" : "outlined"}
                label={data.video}
                icon={<SlowMotionVideo sx={{ height: 20, width: 20 }} />}
              />
              <Chip
                onClick={() =>
                  setState({ ...state, type: "DOCUMENT", url: "" })
                }
                variant={state.type === "DOCUMENT" ? "filled" : "outlined"}
                label={data.document}
                icon={<ArticleOutlined sx={{ height: 20, width: 20 }} />}
              />
              <Chip
                onClick={() => setState({ ...state, type: "AUDIO", url: "" })}
                variant={state.type === "AUDIO" ? "filled" : "outlined"}
                label={data.audio}
                icon={<AudioFileOutlined sx={{ height: 20, width: 20 }} />}
              />
            </Stack>

            <CardActionArea
              component="label"
              sx={{ padding: 10, bgcolor: "#EEEEEE", borderRadius: 4 }}
            >
              {state.type === "AUDIO" && (
                <input
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const selectedFile = e.target.files[0];
                      const maxSize = 16 * 1024 * 1024; // 5MB in bytes
                      if (selectedFile.size > maxSize) {
                        alert(data.audioSizeAlert);
                      } else {
                        returnUrl(selectedFile);
                      }
                    }
                  }}
                  type="file"
                  accept=".aac, .m4a, .amr, .mp3, .opus"
                  hidden
                />
              )}

              {state.type === "DOCUMENT" && (
                <input
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const selectedFile = e.target.files[0];
                      const maxSize = 75 * 1024 * 1024; // 5MB in bytes
                      if (selectedFile.size > maxSize) {
                        alert(data.audioSizeAlert);
                      } else {
                        returnUrl(selectedFile);
                      }
                    }
                  }}
                  type="file"
                  accept=".text, .txt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                  hidden
                />
              )}

              {state.type === "VIDEO" && (
                <input
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const selectedFile = e.target.files[0];
                      const maxSize = 16 * 1024 * 1024; // 5MB in bytes
                      if (selectedFile.size > maxSize) {
                        alert(data.audioSizeAlert);
                      } else {
                        returnUrl(selectedFile);
                      }
                    }
                  }}
                  type="file"
                  accept=".m4v, .mp4, .3gp, .3gpp"
                  hidden
                />
              )}

              {state.type === "IMAGE" && (
                <input
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const selectedFile = e.target.files[0];
                      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                      if (selectedFile.size > maxSize) {
                        alert(data.imgSizeAlert);
                      } else {
                        returnUrl(selectedFile);
                      }
                    }
                  }}
                  type="file"
                  accept=".jpeg, .png, .jpg, .jfif, .pjpeg, .webp"
                  hidden
                />
              )}

              {state.url ? (
                <Typography
                  align="center"
                  fontSize={12}
                  fontWeight={600}
                  color={"green"}
                >
                  {data.uploaded}
                </Typography>
              ) : (
                <Typography
                  align="center"
                  fontSize={12}
                  fontWeight={600}
                  color={"gray"}
                >
                  {data.upload}
                </Typography>
              )}
            </CardActionArea>

            {state.type !== "AUDIO" && (
              <TextFieldNew
                onChange={(e) =>
                  setState({ ...state, caption: e.target.value })
                }
                label={data.caption}
              />
            )}

            <Button
              onClick={() => {
                if (state.type === "AUDIO") {
                  sendAudio();
                } else if (state.type === "DOCUMENT") {
                  sendDocument();
                } else if (state.type === "VIDEO") {
                  sendVideo();
                } else if (state.type === "IMAGE") {
                  sendImage();
                }
              }}
              disabled={state.url ? false : true}
              startIcon={
                state.loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : (
                  <Send />
                )
              }
              variant="contained"
              sx={{ boxShadow: 0, borderRadius: 2 }}
            >
              {data.send}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SendBottomMedia;
