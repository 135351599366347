import {
  Box,
  Chip,
  Dialog,
  Divider,
  FormControl,
  Grid,
  Select,
  Stack,
  Typography,
  InputLabel,
  MenuItem,
  Button,
  Radio,
} from "@mui/material";
import React from "react";
import CloseBtn from "../../../context/CloseBtn";
import TextFieldNew from "../../../components/TextFieldNew";
import moment from "moment";
import { Face, Upload } from "@mui/icons-material";
import { GlobalContext } from "../../../context/GlobalContext";
import AddQuick from "./AddQuick";
import AddAction from "./AddAction";
import AddButton from "./AddButton";
import SideMobile from "./SideMobile";
import { LoadingButton } from "@mui/lab";

const languages = [
  { language: "Afrikaans", code: "af" },
  { language: "Albanian", code: "sq" },
  { language: "Arabic", code: "ar" },
  { language: "Azerbaijani", code: "az" },
  { language: "Bengali", code: "bn" },
  { language: "Bulgarian", code: "bg" },
  { language: "Catalan", code: "ca" },
  { language: "Chinese (CHN)", code: "zh_CN" },
  { language: "Chinese (HKG)", code: "zh_HK" },
  { language: "Chinese (TAI)", code: "zh_TW" },
  { language: "Croatian", code: "hr" },
  { language: "Czech", code: "cs" },
  { language: "Danish", code: "da" },
  { language: "Dutch", code: "nl" },
  { language: "English", code: "en" },
  { language: "English (UK)", code: "en_GB" },
  { language: "English (US)", code: "en_US" },
  { language: "Estonian", code: "et" },
  { language: "Filipino", code: "fil" },
  { language: "Finnish", code: "fi" },
  { language: "French", code: "fr" },
  { language: "Georgian", code: "ka" },
  { language: "German", code: "de" },
  { language: "Greek", code: "el" },
  { language: "Gujarati", code: "gu" },
  { language: "Hausa", code: "ha" },
  { language: "Hebrew", code: "he" },
  { language: "Hindi", code: "hi" },
  { language: "Hungarian", code: "hu" },
  { language: "Indonesian", code: "id" },
  { language: "Irish", code: "ga" },
  { language: "Italian", code: "it" },
  { language: "Japanese", code: "ja" },
  { language: "Kannada", code: "kn" },
  { language: "Kazakh", code: "kk" },
  { language: "Kinyarwanda", code: "rw_RW" },
  { language: "Korean", code: "ko" },
  { language: "Kyrgyz (Kyrgyzstan)", code: "ky_KG" },
  { language: "Lao", code: "lo" },
  { language: "Latvian", code: "lv" },
  { language: "Lithuanian", code: "lt" },
  { language: "Macedonian", code: "mk" },
  { language: "Malay", code: "ms" },
  { language: "Malayalam", code: "ml" },
  { language: "Marathi", code: "mr" },
  { language: "Norwegian", code: "nb" },
  { language: "Persian", code: "fa" },
  { language: "Polish", code: "pl" },
  { language: "Portuguese (BR)", code: "pt_BR" },
  { language: "Portuguese (POR)", code: "pt_PT" },
  { language: "Punjabi", code: "pa" },
  { language: "Romanian", code: "ro" },
  { language: "Russian", code: "ru" },
  { language: "Serbian", code: "sr" },
  { language: "Slovak", code: "sk" },
  { language: "Slovenian", code: "sl" },
  { language: "Spanish", code: "es" },
  { language: "Spanish (ARG)", code: "es_AR" },
  { language: "Spanish (SPA)", code: "es_ES" },
  { language: "Spanish (MEX)", code: "es_MX" },
  { language: "Swahili", code: "sw" },
  { language: "Swedish", code: "sv" },
  { language: "Tamil", code: "ta" },
  { language: "Telugu", code: "te" },
  { language: "Thai", code: "th" },
  { language: "Turkish", code: "tr" },
  { language: "Ukrainian", code: "uk" },
  { language: "Urdu", code: "ur" },
  { language: "Uzbek", code: "uz" },
  { language: "Vietnamese", code: "vi" },
  { language: "Zulu", code: "zu" },
];

const AddTempletDialog = ({ getTemplets, state, setState, data }) => {
  const CON = React.useContext(GlobalContext);
  const [format, setFormat] = React.useState("NONE");
  const [quickReply, setQuickReply] = React.useState([]);
  const [actionBtn, setActionBtn] = React.useState([]);
  const [example, setExample] = React.useState([]);
  const [states, setStates] = React.useState({
    name: "",
    url: "",
    templetFor: "Order Placed",
    mediaType: "IMAGE",
    headerText: "",
    bodyMsg: "",
    footerMsg: "",
    btnType: "NONE",
    quickReplyTitle: "",
    actionUrl: "",
    btnTitle: "",
    phoneNum: "",
    category: "UTILITY",
    language: "",
    webhookType: "",
  });

  function extractVariables(text) {
    // Regular expression to match '{{textsome}}' pattern
    const regex = /\{\{([^{}]+)\}\}/g;
    const matches = [];

    // Using a loop to find all matches
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]); // Capture group 1 contains the text inside {{ }}
    }

    return matches;
  }

  const { hitAxios } = React.useContext(GlobalContext);

  async function returnUrl(media) {
    const fd = new FormData();
    fd.append("file", media);
    const res = await hitAxios({
      path: "/api/user/return_media_url_meta",
      post: true,
      admin: false,
      obj: fd,
    });
    console.log(res.data);
    if (res.data.success) {
      setStates({ ...states, url: res.data.url, hash: res.data.hash });
    }
  }

  function returnHeader() {
    if (format === "TEXT") {
      return {
        text: states?.headerText,
        format: format?.toUpperCase(),
        type: "HEADER",
      };
    } else if (format === "MEDIA") {
      return {
        format: states?.mediaType,
        example: {
          header_handle: [
            states?.url, // Corrected typo here
          ],
        },
        type: "HEADER",
      };
    } else if (format === "NONE") {
      return null;
    } else {
      return []; // Handle other cases where format is not TEXT, MEDIA, or NONE
    }
  }

  function returnBody() {
    return {
      type: "BODY",
      text: states?.bodyMsg,
      example:
        example.length > 0
          ? {
              body_text: example.map((i) => i),
            }
          : null,
    };
  }

  function returnFooter() {
    if (states.footerMsg) {
      return {
        type: "FOOTER",
        text: states.footerMsg,
      };
    } else {
      return null; // Added 'return' keyword here
    }
  }

  function returnButtons() {
    if (states.btnType === "NONE") {
      return null;
    } else if (states.btnType === "QUICK") {
      return {
        type: "BUTTONS",
        buttons: [...quickReply],
      };
    } else if (states.btnType === "ACTION") {
      return {
        type: "BUTTONS",
        buttons: [...actionBtn],
      };
    }
  }

  async function addTemplate() {
    const userId = JSON.parse(localStorage.getItem("myData"))?._id;
    const obj = {
      templetType: "BROADCAST",
      templetFor: "BROADCAST",
      name: states?.name,
      userId: userId,
      language: states?.language,
      category: states?.category,
      components: [
        returnHeader(),
        returnBody(),
        returnFooter(),
        returnButtons(),
      ].filter(Boolean), // Remove null values from the components array
    };

    const res = await hitAxios({
      path: "/api/user/v2/add_meta_templet",
      post: true,
      admin: false,
      obj: obj,
    });
    if (res.data.success) {
      setState({ ...state, addDialog: false });
      setStates({
        name: "",
        url: "",
        mediaType: "IMAGE",
        headerText: "",
        bodyMsg: "",
        footerMsg: "",
        btnType: "NONE",
        quickReplyTitle: "",
        actionUrl: "",
        btnTitle: "",
        phoneNum: "",
        language: "",
      });
      getTemplets();
    }
  }

  function replaceSpacesWithUnderscores(text) {
    return text.replace(/\s+/g, "_");
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={state.addDialog}
      onClose={() => {
        setStates({
          name: "",
          url: "",
          mediaType: "IMAGE",
          headerText: "",
          bodyMsg: "",
          footerMsg: "",
          btnType: "NONE",
          quickReplyTitle: "",
          actionUrl: "",
          btnTitle: "",
          phoneNum: "",
          language: "",
        });
        setState({ ...state, addDialog: false });
      }}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <Box p={2}>
        <Stack mb={1} direction={"column"} spacing={3}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={600}>{data.addNewTemplet}</Typography>
            <CloseBtn
              onClick={() => {
                setState({ ...state, addDialog: false });
                setStates({
                  name: "",
                  url: "",
                  mediaType: "IMAGE",
                  headerText: "",
                  bodyMsg: "",
                  footerMsg: "",
                  btnType: "NONE",
                  quickReplyTitle: "",
                  actionUrl: "",
                  btnTitle: "",
                  phoneNum: "",
                  language: "",
                });
              }}
            />
          </Stack>
        </Stack>
        <Divider variant="middle" />

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={7} lg={9} item>
              <Box pr={2} overflow={"auto"} height={500}>
                <Stack direction={"column"} spacing={3}>
                  <Stack direction={"column"} spacing={1}>
                    <TextFieldNew
                      value={states?.name}
                      onChange={(e) =>
                        setStates({
                          ...states,
                          name: replaceSpacesWithUnderscores(e.target.value),
                        })
                      }
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      bgColor={"#f5f7fb"}
                      placeholder={data.templetName}
                    />
                  </Stack>
                  <Stack direction={"row"} spacing={2}>
                    <Stack direction={"column"} spacing={1}>
                      <Stack direction={"row"} spacing={1}>
                        <Chip
                          sx={{ padding: 1, height: 40 }}
                          onClick={() =>
                            setStates({ ...states, category: "MARKETING" })
                          }
                          variant={
                            states.category === "MARKETING"
                              ? "filled"
                              : "outlined"
                          }
                          label={data.marketing}
                        />
                        <Chip
                          sx={{ padding: 1, height: 40 }}
                          onClick={() =>
                            setStates({ ...states, category: "UTILITY" })
                          }
                          variant={
                            states.category === "UTILITY"
                              ? "filled"
                              : "outlined"
                          }
                          label={data.utility}
                        />
                      </Stack>
                    </Stack>

                    <Stack minWidth={200} direction={"column"} spacing={1}>
                      <FormControl size="small" fullWidth>
                        <InputLabel size="small" id="demo-simple-select-label">
                          {data.language}
                        </InputLabel>
                        <Select
                          sx={{ borderRadius: 2 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          label={data.language}
                          onChange={(e) =>
                            setStates({ ...states, language: e.target.value })
                          }
                        >
                          {languages.map((i, key) => {
                            return (
                              <MenuItem key={key} value={i.code}>
                                {i?.language}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Stack>

                  <Divider variant="middle" />

                  <Stack direction={"column"} spacing={2}>
                    <Typography variant="body2" fontWeight={600}>
                      {data.broadCastTitleOptnl}
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                      <Chip
                        onClick={() => setFormat("NONE")}
                        variant={format === "NONE" ? "filled" : "outlined"}
                        label={data.none}
                      />
                      <Chip
                        onClick={() => setFormat("TEXT")}
                        variant={format === "TEXT" ? "filled" : "outlined"}
                        label={data.text}
                      />
                      <Chip
                        onClick={() => setFormat("MEDIA")}
                        variant={format === "MEDIA" ? "filled" : "outlined"}
                        label={data.media}
                      />
                    </Stack>

                    {format === "TEXT" && (
                      <TextFieldNew
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        bgColor={"#f5f7fb"}
                        label={data.enterText}
                        onChange={(e) =>
                          setStates({ ...states, headerText: e.target.value })
                        }
                        value={states.headerText}
                      />
                    )}

                    {format === "MEDIA" && (
                      <Stack direction={"row"} spacing={2}>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Radio
                            onChange={(e) =>
                              setStates({
                                ...states,
                                mediaType: "IMAGE",
                                url: "",
                              })
                            }
                            size="small"
                            checked={
                              states.mediaType === "IMAGE" ? true : false
                            }
                          />
                          <Typography variant="body2">{data.image}</Typography>
                        </Stack>

                        <Stack direction={"row"} alignItems={"center"}>
                          <Radio
                            onChange={(e) =>
                              setStates({
                                ...states,
                                mediaType: "VIDEO",
                                url: "",
                              })
                            }
                            size="small"
                            checked={
                              states.mediaType === "VIDEO" ? true : false
                            }
                          />
                          <Typography variant="body2">{data.video}</Typography>
                        </Stack>

                        <Stack direction={"row"} alignItems={"center"}>
                          <Radio
                            onChange={(e) =>
                              setStates({
                                ...states,
                                mediaType: "DOCUMENT",
                                url: "",
                              })
                            }
                            size="small"
                            checked={
                              states.mediaType === "DOCUMENT" ? true : false
                            }
                          />
                          <Typography variant="body2">
                            {data.document}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}

                    {states.mediaType === "IMAGE" && format === "MEDIA" && (
                      <Typography
                        sx={{ color: (t) => t.palette.primary.main }}
                        variant="caption"
                      >
                        {data.imageExt}
                      </Typography>
                    )}

                    {states.mediaType === "VIDEO" && format === "MEDIA" && (
                      <Typography
                        sx={{ color: (t) => t.palette.primary.main }}
                        variant="caption"
                      >
                        {data.videoExt}
                      </Typography>
                    )}

                    {states.mediaType === "DOCUMENT" && format === "MEDIA" && (
                      <Typography
                        sx={{ color: (t) => t.palette.primary.main }}
                        variant="caption"
                      >
                        {data.docExt}
                      </Typography>
                    )}

                    {format === "MEDIA" && (
                      <Stack direction={"row"} spacing={1}>
                        <TextFieldNew
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          bgColor={"#f5f7fb"}
                          placeholder={data.uploadMediaPlaceholder}
                          fullWidth
                          onChange={(e) =>
                            setStates({ ...states, url: e.target.value })
                          }
                          value={states.url}
                        />
                        <LoadingButton
                          loading={CON.data?.loading}
                          component="label"
                          fullWidth
                          sx={{ borderRadius: 2 }}
                          startIcon={<Upload />}
                          variant="outlined"
                        >
                          <input
                            hidden
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files.length > 0 &&
                                states.mediaType === "VIDEO"
                              ) {
                                const selectedFile = e.target.files[0];
                                const maxSize = 16 * 1024 * 1024; // 5MB in bytes
                                if (selectedFile.size > maxSize) {
                                  alert(data.audioSizeAlert);
                                } else {
                                  returnUrl(selectedFile);
                                }
                              } else if (
                                e.target.files.length > 0 &&
                                states.mediaType === "DOCUMENT"
                              ) {
                                const selectedFile = e.target.files[0];
                                const maxSize = 75 * 1024 * 1024; // 5MB in bytes
                                if (selectedFile.size > maxSize) {
                                  alert(data.audioSizeAlert);
                                } else {
                                  returnUrl(selectedFile);
                                }
                              } else if (
                                e.target.files.length > 0 &&
                                states.mediaType === "IMAGE"
                              ) {
                                const selectedFile = e.target.files[0];
                                const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                                if (selectedFile.size > maxSize) {
                                  alert(data.imgSizeAlert);
                                } else {
                                  returnUrl(selectedFile);
                                }
                              }
                            }}
                            accept={
                              states.mediaType === "IMAGE"
                                ? ".png, .jpeg"
                                : states.mediaType === "VIDEO"
                                ? ".mp4"
                                : states.mediaType === "DOCUMENT"
                                ? ".pdf"
                                : ""
                            }
                          />
                          {data.uploadMedia}
                        </LoadingButton>
                      </Stack>
                    )}
                  </Stack>

                  <Divider />

                  <Stack direction={"column"} spacing={2}>
                    <Stack direction={"column"}>
                      <Typography variant="body2" fontWeight={600}>
                        {data.body}
                      </Typography>
                      <Typography variant="caption" color={"gray"}>
                        {data.makeYourMessg}
                      </Typography>
                    </Stack>

                    <TextFieldNew
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      bgColor={"#f5f7fb"}
                      value={states.bodyMsg}
                      onChange={(e) => {
                        const inputText = e.target.value.slice(0, 1024); // Limiting input to 1024 characters
                        setStates({ ...states, bodyMsg: inputText });
                      }}
                      helperText={`${states.bodyMsg.length}/${data.allowedType1024}`}
                      multiline
                      rows={4}
                    />
                  </Stack>

                  <Divider />

                  <Stack direction={"column"} spacing={2}>
                    <Stack direction={"column"}>
                      <Typography fontWeight={600} variant="body2">
                        {data.footerOptnl}
                      </Typography>
                      <Typography color={"gray"} variant="caption">
                        {data.footerMsgDes}
                      </Typography>
                    </Stack>
                    <TextFieldNew
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      bgColor={"#f5f7fb"}
                      label={data.footerMsg}
                      value={states.footerMsg}
                      onChange={(e) => {
                        const inputText = e.target.value.slice(0, 60); // Limiting input to 1024 characters
                        setStates({ ...states, footerMsg: inputText });
                      }}
                      helperText={`${states.footerMsg.length}/${data.allowed60}`}
                    />
                  </Stack>

                  <Divider />

                  <Stack direction={"column"} spacing={2}>
                    <Stack direction={"column"}>
                      <Typography fontWeight={600} variant="body2">
                        {data.btnOptnl}
                      </Typography>
                      <Typography color={"gray"} variant="caption">
                        {data.btnOptnlDes}
                      </Typography>
                    </Stack>

                    <Stack direction={"row"} spacing={1}>
                      <Chip
                        variant={
                          states.btnType === "NONE" ? "filled" : "outlined"
                        }
                        onClick={() => {
                          setStates({ ...states, btnType: "NONE" });
                          setQuickReply([]);
                          setActionBtn([]);
                        }}
                        label={data.none}
                      />
                      <Chip
                        variant={
                          states.btnType === "ACTION" ? "filled" : "outlined"
                        }
                        onClick={() => {
                          setStates({ ...states, btnType: "ACTION" });
                          setQuickReply([]);
                          setActionBtn([]);
                        }}
                        label={data.call2Action}
                      />
                      <Chip
                        variant={
                          states.btnType === "QUICK" ? "filled" : "outlined"
                        }
                        onClick={() => {
                          setStates({ ...states, btnType: "QUICK" });
                          setQuickReply([]);
                          setActionBtn([]);
                        }}
                        label={data.quickReply}
                      />
                    </Stack>

                    {states.btnType === "QUICK" && (
                      <AddQuick
                        actionBtn={actionBtn}
                        setStates={setStates}
                        states={states}
                        setQuickReply={setQuickReply}
                        quickReply={quickReply}
                        data={data}
                      />
                    )}

                    {states.btnType === "ACTION" && (
                      <AddAction
                        quickReply={quickReply}
                        setStates={setStates}
                        states={states}
                        setActionBtn={setActionBtn}
                        actionBtn={actionBtn}
                        data={data}
                      />
                    )}
                  </Stack>

                  {extractVariables(states.bodyMsg)?.length > 0 && (
                    <Stack direction={"column"}>
                      <Typography variant="body2" fontWeight={600}>
                        {data.sampleContact}
                      </Typography>
                      <Typography variant="caption" color={"gray"}>
                        {data.sampleContentDes}
                      </Typography>

                      <Stack mt={2} direction={"column"} spacing={1}>
                        {extractVariables(states.bodyMsg)?.map(
                          (variable, key) => (
                            <TextFieldNew
                              sx={{
                                "& fieldset": { border: "none" },
                              }}
                              bgColor={"#f5f7fb"}
                              label={`${data.contentFor} {{${variable}}}`}
                              key={key}
                              value={example[key] || ""} // Provide a default empty string value
                              onChange={(e) => {
                                const updatedExample = [...example]; // Create a copy of the example array
                                updatedExample[key] = e.target.value.slice(
                                  0,
                                  200
                                ); // Update the value at the specified index
                                setExample(updatedExample); // Update the state
                              }}
                              helperText={`${data.sampleContentHelper} - ${
                                example[key]?.length || 0
                              }/${data?.allowed200}`}
                            />
                          )
                        )}
                      </Stack>
                    </Stack>
                  )}

                  <AddButton
                    addTemplate={addTemplate}
                    setStates={setStates}
                    states={states}
                    quickReply={quickReply}
                    actionBtn={actionBtn}
                    data={data}
                  />
                </Stack>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={5} lg={3} item>
              <SideMobile
                actionBtn={actionBtn}
                data={data}
                setStates={setStates}
                states={states}
                format={format}
                quickReply={quickReply}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddTempletDialog;
