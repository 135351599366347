import {
  Avatar,
  Box,
  CardActionArea,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { KeyboardDoubleArrowLeft, Menu } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import ChattingHere from "./ChattingHere";
import moment from "moment";
import SendBottom from "./SendBottom";
import ConversationSide from "./ConversationSide";
import { InboxContext } from "../../../../context/InboxContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useRef } from "react";

const Conversation = ({
  DRAWER_WIDTH,
  state,
  setState,
  INBOX,
  socket,
  chatList,
  data,
  CON,
  getChatList,
  screenWidth,
  setChatList,
}) => {
  const [value, setValue] = React.useState(0);
  const [hasMore, sethasMore] = React.useState(true);
  const { chatConvo } = useContext(InboxContext);
  const [fetchLoading, setFetchloading] = useState(false);
  const chatContainerRef = React.useRef(null);
  const isFetchingOldMessagesRef = React.useRef(false); // Track whether old messages are being fetched
  const fetchMoreData = async (data) => {
    if (INBOX?.data?.openedChat.unqiueBrowserId) {
      return;
    }
    if (!hasMore) {
      return;
    }
    try {
      setFetchloading(true);
      isFetchingOldMessagesRef.current = true;
      const res = await CON.hitAxiosOnly({
        path: "/api/inbox/get_convo",
        post: true,
        admin: false,
        obj: {
          chatId: INBOX?.data?.openedChat.chat_id,
          unqiueBrowserId: INBOX?.data?.openedChat.unqiueBrowserId,
          uid: INBOX?.data?.openedChat.uid,
          messageId: chatConvo[0][0].metaChatId,
        },
      });
      if (res.data.success) {
        if (res.data?.data[0]?.length >= 20) {
          sethasMore(true);
        } else {
          sethasMore(false);
        }
        INBOX.setIsScroll(false);
        INBOX.setChatConvo([[...res.data.data[0], ...chatConvo[0]]]);
        localStorage.setItem(
          "chat",
          JSON.stringify([...res.data.data, ...chatConvo])
        );
      }
      setTimeout(() => {
        isFetchingOldMessagesRef.current = false;
      }, 1500);
      setFetchloading(false);
    } catch (error) {
      setFetchloading(false);
      console.error("Error fetching chat conversation:", error);
      isFetchingOldMessagesRef.current = false;
    }
  };

  function getLastIncomingMessage(mes) {
    if (mes.length < 0) {
      return null;
    }
    let messages = mes[mes?.length - 1];
    let lastIncomingMessage = null;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].route === "INCOMING") {
        lastIncomingMessage = messages[i];
        break;
      }
    }
    return lastIncomingMessage ? lastIncomingMessage.timestamp : null;
  }

  React.useEffect(() => {
    const lastTimestamp = getLastIncomingMessage(chatConvo || []);

    const calculateTimeLeft = () => {
      if (!lastTimestamp) {
        setValue("No timestamp provided");
        return;
      }

      const lastMoment = moment.unix(lastTimestamp);
      const currentMoment = moment();

      const duration = moment.duration(currentMoment.diff(lastMoment));

      // Check if duration exceeds 24 hours
      if (duration.asHours() >= 24) {
        setValue("Expired");
        return;
      }

      // Calculate remaining time from 24 hours
      const remainingTime = moment.duration(24, "hours").subtract(duration);

      // Get remaining hours and minutes
      const remainingHours = Math.floor(remainingTime.asHours());
      const remainingMinutes = remainingTime.minutes();

      // Format and set the value
      setValue(
        `${remainingHours}:${remainingMinutes.toString().padStart(2, "0")}`
      );
    };

    // Initial calculation
    calculateTimeLeft();

    // Recalculate every minute
    const interval = setInterval(calculateTimeLeft, 60000);

    return () => clearInterval(interval);
  }, [chatConvo]);
  useEffect(() => {
    // Fallback to ensure scroll to bottom
    const checkScroll = setTimeout(() => {
      if (INBOX.isScroll) {
        if (chatContainerRef.current) {
          sethasMore(true);
          const { scrollTop, scrollHeight, clientHeight } =
            chatContainerRef.current;
          if (scrollHeight - scrollTop > clientHeight + 1) {
            chatContainerRef.current.scrollTo({
              top: chatContainerRef.current.scrollHeight,
              behavior: "smooth", // Add smooth behavior for scrolling
            });
          }
        }
      }
    }, 500); // Adjust the delay as necessary

    return () => clearTimeout(checkScroll);
  }, [chatConvo]);
  function changeTicket(status) {
    const newArray = chatList.map((el) => {
      if (el.chat_id == INBOX?.data?.openedChat.chat_id) {
        el.chat_status = status;
      }
      return el;
    });
    setChatList(newArray);
    INBOX.setData({
      ...INBOX.data,
      openedChat: { ...INBOX?.data?.openedChat, chat_status: status },
    });
    socket.emit("change_ticket_status", {
      uid: CON.getMyDetails?.uid,
      status,
      chatId: INBOX?.data?.openedChat?.chat_id,
    });
  }
  const changeTransferAgent = async (agentConnect) => {
    const myData = JSON.parse(localStorage.getItem("myData"));
    const res = await CON.hitAxios({
      path: agentConnect ? "/api/inbox/connect" : "/api/inbox/disconnect",
      post: true,
      admin: true,
      obj: {
        chatId: INBOX?.data?.openedChat?.chat_id,
        firstName: myData.firstName,
        email: myData.email,
      },
    });
    if (res.status === 200 && res.data.success) {
      INBOX.setData({
        ...INBOX.data,
        openedChat: {
          ...INBOX.data.openedChat,
          agentConnect,
        },
      });
      INBOX.setChatList(
        INBOX.chatList.map((el) => {
          if (el.chat_id == INBOX?.data.openedChat.chat_id) {
            el.agentConnect = agentConnect;
          }
          return el;
        })
      );
    }
  };

  const open = (
    <Box sx={{ padding: "3px 7px 3px 7px" }} bgcolor={"#eef7e7"}>
      <Typography color={"#5da570"} fontSize={16} fontWeight={600}>
        🧐 {data.open}
      </Typography>
    </Box>
  );

  const pending = (
    <Box
      sx={{ padding: "3px 7px 3px 7px", borderRadius: "10px" }}
      bgcolor={"#fef7da"}
      style={{ borderRadius: "10px" }}
    >
      <Typography color={"#f5bd1d"} fontSize={16} fontWeight={600}>
        😅 {data.pending}
      </Typography>
    </Box>
  );

  const solved = (
    <Box sx={{ padding: "3px 7px 3px 7px" }} bgcolor={"#e5f7ff"}>
      <Typography color={"#72b5cf"} fontSize={16} fontWeight={600}>
        😇 {data.solved}
      </Typography>
    </Box>
  );

  const ticketComp = (
    <Box>
      <Tooltip title={`Change chat status to solved`}>
        <CardActionArea onClick={() => changeTicket("solved")}>
          {INBOX?.data?.openedChat?.chat_status === "open" && open}
        </CardActionArea>
      </Tooltip>

      <Tooltip title={`Change chat status to pending`}>
        <CardActionArea onClick={() => changeTicket("pending")}>
          {INBOX?.data?.openedChat?.chat_status === "solved" && solved}
        </CardActionArea>
      </Tooltip>

      <Tooltip title={`Change chat status to open`}>
        <CardActionArea onClick={() => changeTicket("open")}>
          {INBOX?.data?.openedChat?.chat_status === "pending" && pending}
        </CardActionArea>
      </Tooltip>
    </Box>
  );

  return (
    <Box
      sx={{
        width: screenWidth > DRAWER_WIDTH ? screenWidth - 340 : "100%",
      }}
    >
      <Stack
        pr={0.5}
        pl={0.5}
        height={"92vh"}
        direction={"column"}
        justifyContent={"space-between"}
      >
        <Box p={1} bgcolor={"white"}>
          <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            direction={"row"}
            spacing={2}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {screenWidth < DRAWER_WIDTH && (
                <IconButton
                  onClick={() => setState({ ...state, drawer: !state.drawer })}
                >
                  <Menu />
                </IconButton>
              )}

              <Avatar>
                {INBOX?.data?.openedChat?.sender_name &&
                  INBOX?.data?.openedChat?.sender_name
                    ?.slice(0, 1)
                    ?.toUpperCase()}
              </Avatar>

              <Stack direction={"column"}>
                <Typography fontWeight={600}>
                  {INBOX?.data?.openedChat?.contact
                    ? INBOX?.data?.openedChat?.contact?.name
                    : INBOX?.data?.openedChat?.sender_name}
                </Typography>
                <Typography color={"gray"} variant="caption" fontWeight={500}>
                  +
                  {INBOX?.data?.openedChat?.sender_mobile &&
                    INBOX?.data?.openedChat?.sender_mobile}
                </Typography>
              </Stack>
            </Stack>

            <Stack spacing={2} direction={"row"} alignItems={"center"}>
              <Box>
                <Tooltip
                  title={
                    INBOX?.data?.openedChat?.agentConnect
                      ? "Transfer to Bot"
                      : "Transfer to Agent"
                  }
                >
                  <CardActionArea
                    onClick={() =>
                      changeTransferAgent(
                        INBOX?.data?.openedChat?.agentConnect ? 0 : 1
                      )
                    }
                  >
                    <Box
                      sx={{ padding: "3px 7px 3px 7px" }}
                      bgcolor={"#eef7e7"}
                    >
                      <Typography
                        color={"#5da570"}
                        fontSize={16}
                        fontWeight={600}
                      >
                        {INBOX?.data?.openedChat?.agentConnect
                          ? "Bot"
                          : "Agent"}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </Tooltip>
              </Box>
              {screenWidth > 488 && ticketComp}

              <CircularProgressWithLabel data={data} value={value} />

              <Tooltip title={data.openMenu}>
                <IconButton
                  onClick={() => setState({ ...state, sideBardDialog: true })}
                  size="large"
                >
                  <KeyboardDoubleArrowLeft sx={{ height: 30, width: 30 }} />
                </IconButton>
              </Tooltip>

              <ConversationSide
                ticketComp={ticketComp}
                getChatList={getChatList}
                DRAWER_WIDTH={DRAWER_WIDTH}
                state={state}
                setState={setState}
                INBOX={INBOX}
                // socket={socket}
                chatList={chatList}
                data={data}
                CON={CON}
                chatConvo={chatConvo}
                screenWidth={screenWidth}
              />
            </Stack>
          </Stack>
        </Box>

        <Box
          style={{
            scrollBehavior: "smooth",
          }}
          height={"100%"}
          overflow={"auto"}
          ref={chatContainerRef}
          id="scrollableDiv"
          onScroll={() => {
            // Trigger fetchMoreData if user scrolls to the top
            if (chatContainerRef.current.scrollTop === 0) {
              fetchMoreData();
            }
          }}
        >
          {fetchLoading == true && (
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              {" "}
              <CircularProgress />
            </Box>
          )}
          <InfiniteScroll
            dataLength={chatConvo?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
            inverse={true} // This is important for loading previous messages when scrolling up
            pullDownToRefresh={false}
          >
            <ChattingHere value={value} data={data} chatConvoData={chatConvo} />
          </InfiniteScroll>
        </Box>

        {INBOX?.data?.openedChat.agentConnect ? (
          <SendBottom
            value={value}
            data={data}
            chatConvo={chatConvo}
            INBOX={INBOX}
          />
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
};

export default Conversation;
